import React, { useState, useEffect } from "react"
import _ from "lodash"
import { Header } from "../../components/header"
import { DashboardContainer } from "../../components/dashboard-container"
import {
  IContract,
  IGetAllContractsResponse,
} from "../../types/interfaces/IGetAllContractsResponse"
import { DataRefreshAndTimestamp } from "../../components/data-refresh-and-timestamp"
import { useAllKontentResourceStringData } from "../../graphql-static/use-resource-strings"
import { IFilters } from "../../types/interfaces/IFilters"
import { AdvancedFileringMenu } from "../../components/advanced-filtering"
import { TableLister } from "../../components/table-lister"
import { IGetAllTenantsResponse } from "../../types/interfaces/IGetAllTenantsResponse"
import {
  applyAdvancedFiltering,
  applyPillFiltering,
} from "../../utils/filter-helper"
import CardLister from "../../components/card-lister/card-lister"
import baseFilters from "../../mocks/baseFilters.json"
import baseContracts from "../../mocks/baseContracts.json"
import { ContractStatus } from "../../types/enums/contract-status"
import { LoadingFeedback } from "../../components/loading-feedback"
import { getTotals } from "../../utils/contract-helper"
import { getResourceStringByid } from "../../utils/resource-string-helper"
import { useQueryParams } from "../../utils/browser-helper"
import { navigate } from "gatsby-link"

export default function Home() {
  const resourceStrings = useAllKontentResourceStringData()

  const [contracts, setContracts] =
    useState<IGetAllContractsResponse>(baseContracts)
  const [filteredContracts, setFilteredContracts] = useState<IContract[]>([])
  const [tenantUsers, getAllTenantUsers] = useState<IGetAllTenantsResponse>({
    internalGetAllUsers: [],
  })

  // Check for any query params to apply filters
  const queryParamsActiveFilters = useQueryParams().get("filters")
  const initialFilters = queryParamsActiveFilters ? JSON.parse(queryParamsActiveFilters) : baseFilters
  const filterOptionsOpen = !!useQueryParams().get("filterOptionsOpen")

  const [activeFilters, setActiveFilters] = useState<IFilters>(initialFilters)
  const [isFilterOptionsOpen, setIsFilterOptionsOpen] = useState<boolean>(filterOptionsOpen)
  const [firstLoadSpinner, setFirstLoadSpinner] = useState<boolean>(true)

  const updateFilterState = (state: Partial<IFilters>) => {
    setActiveFilters({ ...activeFilters, ...state })
  }

  const updateContractStatusCallback = (
    status: ContractStatus,
    contractNo: number
  ) => {
    let updatedStatusContracts = _.cloneDeep(contracts)

    if (status === ContractStatus.BOOKED || status === ContractStatus.XHQUOTED) {
      updatedStatusContracts.internalGetAllContracts.contracts =
        updatedStatusContracts.internalGetAllContracts.contracts.filter(
          contract => {
            return contract.contractNo !== contractNo
          }
        )

      updatedStatusContracts.internalGetAllContracts.totals = getTotals(
        updatedStatusContracts.internalGetAllContracts.contracts
      )
    } else {
      updatedStatusContracts.internalGetAllContracts.contracts =
        updatedStatusContracts.internalGetAllContracts.contracts.map(
          contract => {
            return contract.contractNo === contractNo
              ? updateStatusFunction(contract, status)
              : contract
          }
        )
    }

    setContracts(updatedStatusContracts)
  }

  const updateContractTenantCallback = (tenant: string, contractNo: number) => {
    let updatedTenantContracts = _.cloneDeep(contracts)
    updatedTenantContracts.internalGetAllContracts.contracts =
      updatedTenantContracts.internalGetAllContracts.contracts.map(contract => {
        return contract.contractNo === contractNo
          ? updateTenantFunction(contract, tenant)
          : contract
      })

    setContracts(updatedTenantContracts)
  }

  const updateTenantFunction = (contract: IContract, tenant: string) => {
    contract.assignment = tenant
    return contract
  }

  const updateStatusFunction = (
    contract: IContract,
    status: ContractStatus
  ) => {
    const updatedContract = contract
    updatedContract.status = status

    const today: any = new Date()
    const dateRaised: any = new Date(updatedContract.dateRaised)
    const diffTime = Math.abs(dateRaised - today)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (
      updatedContract.status === ContractStatus.NOT_STARTED &&
      updatedContract.urgentActionRequired === false &&
      diffDays > 2
    ) {
      updatedContract.urgentActionRequired = true
    }

    if (
      updatedContract.status !== ContractStatus.NOT_STARTED &&
      contract.urgentActionRequired === true
    ) {
      updatedContract.urgentActionRequired = false
    }
    return updatedContract
  }

  const handleNavigateToContractPage = (contractId: number) => {
    // Check if there are filters applied.
    // If yes, add a flag and the applied filters to the state so the contract page knows to include a 
    // "Filtered Contracts" menu option to navigate back with the filters applied.
    const hasFilters = JSON.stringify(activeFilters) !== JSON.stringify(baseFilters)
    let state = {
      hasFilters: false,
      filters: ""
    }

    if (hasFilters) {
      const filters = encodeURIComponent(JSON.stringify(activeFilters))
      const filterOptionsOpen = isFilterOptionsOpen ? "&filterOptionsOpen=true" : ""
      state.hasFilters = true
      state.filters = `${filters}${filterOptionsOpen}`
    }

    navigate(`/solutions-dashboard/contract/${contractId}`, { state })
  }

  const applyFilters = () => {
    let filteredContracts = contracts.internalGetAllContracts.contracts
    filteredContracts = applyAdvancedFiltering(filteredContracts, activeFilters)
    filteredContracts = applyPillFiltering(filteredContracts, activeFilters)

    setFilteredContracts(filteredContracts)
  }

  // Everytime our base dataset updates re-apply the currently active filters
  useEffect(() => {
    applyFilters()
  }, [contracts])

  // Apply filters to our dataset if they change
  useEffect(() => {
    applyFilters()
  }, [activeFilters])

  return (
    <main className="relative">
      <LoadingFeedback showSpinner={firstLoadSpinner} />
      <Header headerTitle="" currentPage="solutions-dashboard" />
      <DashboardContainer>
        <CardLister
          sameDay={contracts.internalGetAllContracts.totals.sameDay}
          nextDay={contracts.internalGetAllContracts.totals.nextDay}
          future={contracts.internalGetAllContracts.totals.future}
          updateFilterState={updateFilterState}
        />
        <div className="mt-8 mb-8">
          <h1 className="inline-flex mr-6 text-3xl font-bold uppercase">
            {getResourceStringByid(
              resourceStrings,
              "0fc2d5b1-da2b-55ba-ad99-66eaac6eaf20"
            )}
          </h1>
          <DataRefreshAndTimestamp
            callback={(data: IGetAllContractsResponse, tenants: any) => {
              setContracts(data)
              getAllTenantUsers(tenants)
              if (firstLoadSpinner) {
                setFirstLoadSpinner(false)
              }
            }}
            interval={120000}
          />
        </div>
      </DashboardContainer>
      <DashboardContainer>
        <AdvancedFileringMenu
          contracts={contracts.internalGetAllContracts.contracts}
          activeFilters={activeFilters}
          updateFilterState={updateFilterState}
          isFilterOptionsOpen={isFilterOptionsOpen}
          setIsFilterOptionsOpen={setIsFilterOptionsOpen}
        />
      </DashboardContainer>
      <DashboardContainer>
        <TableLister
          updateContractTenantCallback={(tenant, contractId) =>
            updateContractTenantCallback(tenant, contractId)
          }
          contracts={filteredContracts}
          tenants={tenantUsers.internalGetAllUsers}
          activeFilters={activeFilters}
          updateContractStatusCallback={(status, contractId) =>
            updateContractStatusCallback(status, contractId)
          }
          handleNavigateToContractPage={handleNavigateToContractPage}
        />
      </DashboardContainer>
    </main>
  )
}
